import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Header from "../components/header";
import Intro from "../components/blocks/intro";
import IntroText from "../components/blocks/intro_text";

import MegaPixel from "../components/games/megapixel";
import Dank from "../components/games/dank";
import Laser from "../components/games/laser";
import Quest from "../components/games/quest";
import Hit from "../components/games/hit";
import Steps from "../components/games/steps";
import Events from "../components/blocks/events";
import Contact from "../components/blocks/contact";
import Gallery from "../components/blocks/gallery";

const Holder = styled.div`
    background-color: #000;
    background-color: rgb(17, 17, 17);
`;

export default function Home() {
    const [width, setWidth] = useState(window.innerWidth);
    // const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
            // setHeight(window.innerHeight);
        });
    }, []);

    return (
        <Holder>
            <Header />
            <Intro />
            <IntroText
                id="about"
                tag="/img/tag_about.svg"
                text="Um ambiente completo e inédito para todas as idades. Jogos interativos, café, eventos, loja, atrações, muita tecnologia e inovação. Você está preparado?"
                leftBackground="about_bg_left"
                rightBackground="about_bg_right"
                width={width}
            />
            <Steps width={width} />
            <IntroText
                id="games"
                tag="/img/tag_games.svg"
                text="Cinco salas com dinâmicas e jogos que desafiarão o seu time. Uma experiência memorável e imersiva para você se divertir entre amigos e família! Dá uma olhada!"
                leftBackground="games_bg_left"
                rightBackground="games_bg_right"
                width={width}
            />
            <MegaPixel />
            <Dank />
            <Laser />
            <Quest />
            <Hit />
            <IntroText
                id="space"
                tag="/img/tag_espaco.svg"
                text="Além do circuito Pixel, a Arena conta com um hub com arquibancada, painel de LED, obras interativas, atrações, café, loja oficial e muito mais."
                width={width}
            />
            <Gallery />
            <IntroText
                id="events"
                tag="/img/tag_event.svg"
                text="Salões inovadores, com cenografia e tecnologia de alto impacto, prontos para receber o seu evento! Você nunca viu nada assim! 
Pronto para comemorar?"
                width={width}
            />
            <Events />
            <Contact id="contact" />
        </Holder>
    );
}
